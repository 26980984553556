import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { ChartBarIcon } from "@heroicons/react/24/solid";
import {
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
  Select,
  Option
} from "@material-tailwind/react";
import React from "react";

function Membership() {
  return (<React.Fragment>
    <div className="grid grid-cols-6 ">
      <div className="col-span-1">
        <Select>
          <Option value="<30">
            Past 30 days
          </Option>
        </Select>
      </div>
      <div className="col-span-1 col-end-7">
        <div className="text-gray-500 flex text-md font-semibold"><span><ExclamationCircleIcon height={15} style={{ marginTop: 4, marginRight: 5 }} /></span><span>Last updated at 8:43 PM</span></div>
      </div>
    </div>

    <div className="bg-white rounded border shadow-md my-5 min-h-[400px]">
      <Tabs id="custom-animation" value='new' >
        <TabsHeader className="h-full ">
          <Tab value={'new'} >
            New
            <div className="text-lg">0</div>
          </Tab>
          <Tab value={'canceled'} >
            Canceled
            <div className="text-lg">0</div>
          </Tab>
        </TabsHeader>
        <TabsBody className="">
          <TabPanel value={'new'}>
            <div className="flex items-center justify-center min-h-[350px]">
              <div className="rounded-sm bg-gray-200 flex col-span-3 w-1/2 justify-center items-center p-5">

                <div><ChartBarIcon height={15} style={{ marginTop: 3, marginRight: 10 }} /></div>
                <div>Your patron growth over time will show up here</div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={'canceled'}>
            <div className="flex items-center justify-center min-h-[350px]">
              <div className="rounded-sm bg-gray-200 flex col-span-3 w-1/2 justify-center items-center p-5">

                <div><ChartBarIcon height={15} style={{ marginTop: 3, marginRight: 10 }} /></div>
                <div>Your patron growth over time will show up here</div>
              </div>
            </div>
          </TabPanel>
        </TabsBody>

      </Tabs>
    </div>
  </React.Fragment>)
}

export default function InsightCreator() {
  return (
    <React.Fragment>
      <div className='font-bold text-xl'>
        Insight
      </div>

      {/* <div className="grid grid-cols-2 my-5"> */}
      <div>
        <Tabs id="custom-animation" value="membership"  >
          <TabsHeader className="w-full md:w-1/2">
            <Tab value={'membership'} >
              Membership
            </Tab>
            <Tab value={'earnings'} >
              Earnings
            </Tab>
            <Tab value={'posts'} >
              Posts
            </Tab>
          </TabsHeader>
          <TabsBody
            animate={{
              initial: { y: 250 },
              mount: { y: 0 },
              unmount: { y: 250 },
            }}
            className='p-0'
          >
            <TabPanel value={'membership'} className='px-0 py-2'>

              <Membership />
            </TabPanel>
            <TabPanel value={'earnings'} className='px-0 py-2'>

              <Membership />
            </TabPanel>
            <TabPanel value={'posts'} className='px-0 py-2'>

              <Membership />
            </TabPanel>
          </TabsBody>
        </Tabs>
      </div>

      {/* </div> */}
    </React.Fragment>
  )
}