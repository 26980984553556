import React from "react";
import { Toaster } from "react-hot-toast";
import CreatorSidebar from "./sidebar";

interface IProps {
  children?: React.ReactNode;
}

export default function CreatorLayout({
  children,
}: IProps) {
  return <>
    <div className="flex w-full h-screen inter-base-regular text-grey-90">
      <Toaster
        containerStyle={{
          top: 74,
          left: 24,
          bottom: 24,
          right: 24,
        }}
      />
      <CreatorSidebar />
      <div className="flex flex-col flex-1">
        <div className=" bg-grey-5">
          {/* <TopBar /> */}
        </div>
        {/* hello */}
        <div className="large:px-2xlarge py-xlarge bg-grey-5 min-h-content overflow-y-auto">
          {/* <main className="xsmall:mx-base small:mx-xlarge medium:mx-4xlarge large:mx-auto large:max-w-7xl large:w-full h-full"> */}
          <main>{children}</main>
        </div>
      </div>
    </div>
  </>
}