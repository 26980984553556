import { BellAlertIcon, ChartBarIcon, CircleStackIcon, Cog6ToothIcon, EnvelopeIcon } from "@heroicons/react/24/outline"
import React, { useState } from "react"
import CreatorSidebarMenuItem from "../creator-layout/SideBarItem"

import TagIcon from "../fundamentals/icons/tag-icon"

const ICON_SIZE = 18

export default function CreatorSidebar() {
  const [currentlyOpen, setCurrentlyOpen] = useState(-1)

  const triggerHandler = () => {
    const id = triggerHandler.id++
    return {
      open: currentlyOpen === id,
      handleTriggerClick: () => setCurrentlyOpen(id),
    }
  }

  triggerHandler.id = 0

  return (
    <>

      <div className="min-w-sidebar max-w-sidebar h-screen overflow-y-auto bg-gray-0 border-r border-grey-20  p-2 ">
        {/* <div className="flex items-center  px-2.5 pb-6 w-full mb-4 text-black">
          <span className="font-semibold ml-2.5 text-xl ">{"Salon"}</span>
        </div> */}
        <div className="  px-base py-10 pb-4 rounded-lg h-fit ">
          <CreatorSidebarMenuItem
            pageLink={"/creator/insight"}
            icon={<ChartBarIcon height={18} />}
            text={"Insight"}
            triggerHandler={triggerHandler}
          />

          <CreatorSidebarMenuItem
            pageLink={"/creator/payout"}
            icon={<CircleStackIcon height={18} />}
            text={"Payout"}
            triggerHandler={triggerHandler}
          />


          <CreatorSidebarMenuItem
            pageLink={"/creator/finance"}
            icon={<EnvelopeIcon height={18} />}
            text={"Finance"}
            triggerHandler={triggerHandler}
          />

          <CreatorSidebarMenuItem
            pageLink={"/creator/notifications"}
            icon={<BellAlertIcon height={18} />}
            text={"Notification"}
            triggerHandler={triggerHandler}
          />

          <CreatorSidebarMenuItem
            pageLink={"/creator/settings"}
            icon={<Cog6ToothIcon height={18} />}
            text={"Settings"}
            triggerHandler={triggerHandler}
          />


          {/* <SidebarMenuItem
            pageLink={"/nail/appointments"}
            icon={<ClockIcon size={ICON_SIZE} />}
            text={"Appointments"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={"/nail/clients"}
            icon={<CustomerIcon size={ICON_SIZE} />}
            text={"Clients"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={"/nail/services"}
            icon={<ClipboardCopyIcon size={ICON_SIZE} />}
            text={"Services"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={"/nail/messages"}
            icon={<DetailsIcon size={ICON_SIZE} />}
            text={"Messages"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={"/nail/reviews"}
            icon={<EyeIcon size={ICON_SIZE} />}
            text={"Reviews"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={"/nail/finance"}
            icon={<CoinsIcon size={ICON_SIZE} />}
            text={"Finances"}
            triggerHandler={triggerHandler}
          />

          <SidebarMenuItem
            pageLink={"/nail/setting"}
            icon={<GearIcon size={ICON_SIZE} />}
            text={"Setting"}
            triggerHandler={triggerHandler}
          /> */}
        </div>
      </div>
    </>
  )
}